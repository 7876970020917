import type { InferGetStaticPropsType, GetStaticProps } from 'next'
import { getStoryblokApi, ISbStoryData } from '@storyblok/react/rsc'
import StoryblokStory from '@storyblok/react/story'
import { notFound } from 'next/navigation'
import Metadata from '@/components/Metadata'

export default function Page(
  props: InferGetStaticPropsType<typeof getStaticProps>
) {
  return (
    <>
      <Metadata story={props.story} />
      <StoryblokStory story={props.story} />
    </>
  )
}

async function fetchData(slug: string) {
  const sbParams = {
    version: (process.env.NODE_ENV === 'development'
      ? 'draft'
      : 'published') as 'draft',
    cv: Date.now(),
  }

  const storyblokApi = getStoryblokApi()

  try {
    const data = await storyblokApi.get(`cdn/stories/${slug}`, sbParams)
    return data
  } catch {
    notFound()
  }
}

export const getStaticProps = (async () => {
  const { data } = await fetchData('home')
  return { props: { story: data.story } }
}) satisfies GetStaticProps<{
  story: ISbStoryData
}>
