import { ISbStoryData } from '@storyblok/react'
import Head from 'next/head'

const Metadata = ({ story }: { story: ISbStoryData }) => {
  const BASE_URL =
    process.env.NEXT_PUBLIC_APP_URL || 'https://www.nationaldigital.com.au'
  const SITE_NAME = 'National Digital'
  const { pageSeo } = story.content

  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="next-size-adjust" content="" />
      <link rel="icon" href="/favicon.ico" type="image/x-icon" sizes="48x48" />
      <title>{`${pageSeo?.title} | ${SITE_NAME}`}</title>
      <meta name="description" content={pageSeo?.description} />
      <meta name="author" content={SITE_NAME} />
      <link rel="canonical" href={new URL(BASE_URL).toString()} />
      <meta property="og:title" content={`${pageSeo?.title} | ${SITE_NAME}`} />
      <meta property="og:description" content={pageSeo?.og_description} />
      <meta property="og:url" content={new URL(BASE_URL).toString()} />
      <meta property="og:site_name" content={SITE_NAME} />
      <meta property="og:locale" content="en_AU" />
      <meta
        property="og:image"
        content={`${pageSeo?.og_image}/m/2600x1350/smart`}
      />
      <meta property="og:image:width" content="2600" />
      <meta property="og:image:height" content="1350" />
      <meta property="og:image:alt" content={pageSeo?.og_title} />
      <meta property="og:type" content="article" />
      <meta
        property="article:published_time"
        content={story.first_published_at as string}
      />
      <meta
        property="article:modified_time"
        content={story.published_at as string}
      />
      <meta property="article:author" content={SITE_NAME} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={`${pageSeo?.title} | ${SITE_NAME}`} />
      <meta name="twitter:description" content={pageSeo?.twitter_description} />
      <meta
        name="twitter:image"
        content={`${pageSeo?.twitter_image}/m/1600x900/smart`}
      />
      <meta name="twitter:image:width" content="1600" />
      <meta name="twitter:image:height" content="900" />
      <meta name="twitter:image:alt" content={pageSeo?.twitter_title} />
    </Head>
  )
}

export default Metadata
